import React from "react";
import logo from "./logo.png";
import "./App.css";
import Gallery from "./components/gallery/Gallery";
import NavBar from "./components/navbar/Navbar";

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Gallery></Gallery>
      <footer className="footer">Copyright © 2023 Paintbug Art</footer>
    </div>
  );
}

export default App;
