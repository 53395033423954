import React, { useState, useCallback } from "react";
import PhotoAlbum, { RenderPhoto, Photo } from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import "yet-another-react-lightbox/styles.css";
import './Gallery.css';
import BlurredUpImage from "../blurredImage/BlurredImage";

const photos: Photo[] = [
  { src: "/images/1.jpg", width: 600, height: 600, alt: "painting" },
  { src: "/images/2.jpg", width: 720, height: 600, alt: "painting" },
  { src: "/images/3.jpg", width: 780, height: 600, alt: "painting" },
  { src: "/images/4.jpg", width: 700, height: 600, alt: "painting" },
  { src: "/images/5.jpg", width: 800, height: 600, alt: "painting" },
  { src: "/images/6.jpg", width: 750, height: 600, alt: "painting" },
  { src: "/images/7.jpg", width: 1200, height: 900, alt: "painting" },
  { src: "/images/8.jpg", width: 750, height: 1000, alt: "painting" },
  { src: "/images/9.jpg", width: 600, height: 600, alt: "painting" },
  { src: "/images/10.jpg", width: 1200, height: 900, alt: "painting" },
  { src: "/images/11.jpg", width: 700, height: 900, alt: "painting" },
  { src: "/images/12.jpg", width: 700, height: 900, alt: "painting" },
  { src: "/images/13.jpg", width: 1200, height: 900, alt: "painting" },
];


export default function Gallery() {

  const [index, setIndex] = useState(-1);

  const renderPhoto: RenderPhoto = ({ layout, layoutOptions, imageProps: { alt, style, ...restImageProps } }) => (
    <div
      style={{
        // border: "2px solid #eee",
        borderRadius: "4px",
        boxSizing: "content-box",
        alignItems: "center",
        width: style?.width,
        padding: `${layoutOptions.padding - 2}px`,
        paddingBottom: 0,
      }}
    >
      {/* <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} /> */}
      <BlurredUpImage imgSrc={restImageProps?.src} alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} ></BlurredUpImage>
      {/* <div
        style={{
          paddingTop: "8px",
          paddingBottom: "8px",
          overflow: "visible",
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        {Math.round(layout.width) + " x " + Math.round(layout.height)}
      </div> */}
    </div>
  );
  

  return (
    <section className="gallery">
      <PhotoAlbum layout="rows" photos={photos} onClick={({ index }) => setIndex(index)} renderPhoto={renderPhoto} />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow]}
      />
    </section>
  );
}
