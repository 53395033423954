import React from "react";
import useProgressiveImg from "../../Hooks/ProgressiveImage";

const BlurredUpImage = (props:
    { imgSrc: string, style: any, alt?: string }) => {
    const { imgSrc, style, ...restProps } = props
    const [src, { blur }] = useProgressiveImg("/images/tiny.jpg", imgSrc);
    return (
        <img
            src={src}
            style={{
                ...style,
                filter: blur ? "blur(20px)" : "none",
                transition: blur ? "none" : "filter 0.3s ease-out"
            }}
        />
    );
};


export default BlurredUpImage;